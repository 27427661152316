#skills {
    overflow-y: hidden;
}

.skill-icon, .fe-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.skill-icon p, .fe-icon p {
    margin-top: 10px;
    font-size: 18px;
}

.skill-icon svg, .fe-icon svg {
    color: #4CAF50;
}

.fe-icon svg {
    color: #008CBA;
}

.skill-icon, .fe-icon {
    transition: transform 0.5s ease-out;
}

.skill-icon:hover, .fe-icon:hover {
    transform: translate(0, -1vh);
}

@media (min-width: 768px) {
    .skill-icon, .fe-icon {
        width: 50%;
        padding: 0 10px;
    }
}