.App {
  text-align: center;
  overflow-y: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1vw + 2vh);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  background-color: #0D1B2A !important;
}

.full-page-panel {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FFFAFF;
  color: #0D1B2A;
  margin: 0;
  overflow-x: hidden;
}

.full-page-panel-alternate {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0D1B2A;
  color: #E0E1DD;
  margin: 0;
  position: relative;
  overflow-x: hidden;
}

.scroll-prompt {
  position: absolute;
  bottom: 5%;
  width: 100%;
  text-align: center;
}

.scroll-prompt span {
  display: block;
  font-size: 2vh;
  margin-bottom: 1vh;
}

.scroll-prompt i {
  font-size: 3vh;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2vh);
  }
  100% {
    transform: translateY(0);
  }
}

.navbar {
  font-size: 0.8vw;
  background-color: #0D1B2A;
  height: 12%;
  transition: height 0.5s ease-in-out, font-size 0.5s ease-in-out;
}

.nav-link, #nameLink {
  font-family: 'Helvetica';
  margin-right: 5%;
  margin-left: 5%;
  color: #E0E1DD !important;
  position: relative;
}

.nav-link::after, #nameLink::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0.2vh;
  background-color: #00a9e0;
  transition: width 0.3s ease-in-out;
}

.nav-link:hover::after,
.nav-link.active::after,
#nameLink:hover::after,
#nameLink.active::after {
  width: 100%;
}

.active {
  font-weight: bold;
  text-decoration: underline;
}

.navbar-big {
  height: 15%;
  font-size: 1vw;
}

.navbar-brand {
  font-family: 'Pacifico', cursive;
  color: #FFFAFF;
  font-size: 2.5vh;
}

.brand-text-big {
  font-family: 'Open Sans', sans-serif;
  font-size: 4vh;
  color: #FFFAFF;
  text-transform: uppercase;
  letter-spacing: 0.4vw;
  transition: font-size 0.5s ease-in-out, letter-spacing 0.5s ease-in-out;
}

.brand-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 3vh;
  color: #FFFAFF;
  text-transform: uppercase;
  letter-spacing: 0.2vw;
  transition: font-size 0.5s ease-in-out, letter-spacing 0.5s ease-in-out;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  will-change: opacity;
}

.fade-in-active {
  opacity: 1;
  transform: none;
}

.navbar-toggler {
  border-color: #E0E1DD;
  background-color: transparent;
}


.custom-navbar .navbar-collapse {
  background-color: #0D1B2A;
  padding-bottom: 1.5vh;
}

.custom-navbar .navbar-nav .nav-link {
  margin-left: 0%;
  margin-top: 3%;
  color: #E0E1DD;
}

.custom-navbar .navbar-toggler {
  color: #E0E1DD;
  position: absolute;
  top: 25%;
  right: 5%;
  z-index: 1;
}

.custom-navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23e0e1dd' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.custom-navbar .navbar-nav .nav-link {
  font-size: 18px;
}

@media (max-width: 1025px) {
  .brand-text-big {
    font-size: 2vh;
  }

  .brand-text {
    font-size: 1.5vh;
  }

  .nav-link:hover::after,
  .nav-link.active::after {
    width: 20%;
  }

  #nameLink:hover::after,
  #nameLink.active::after {
    width: 105%;
  }

  #nameLink {
    display: flex;
    justify-content: center;
    margin-left: 0%;
  }

  .custom-navbar {
    justify-content: center;
  }

  .custom-navbar .navbar-toggler-icon {
    font-size: 3vh;
  }

  .custom-navbar .navbar-nav .nav-link {
    margin-top: 1.5%;
  }

  .nav-link, #nameLink {
    margin-top: 2%;
    margin-right: 0%;
  }
}

/* This is for landscape mode */
@media (max-height: 389px) and (max-width: 843px) {
  .custom-navbar .navbar-toggler {
    top: 20%;
  }

  #nameLink {
    margin-left: 5%;
  }
}

