.form-field-left {
    margin: 2.17% 50%;
    padding: 5px 5px;
    font-size: 2.22vh;
}
  
.form-field-right {
    margin: 1.5% 0%;
    height: 20vh;
}

@media screen and (min-width: 992px) {
    .form-field-right {
        margin: 2.5% 0%;
    }
}

@media screen and (max-width: 991px) {
    .form-field-right {
        margin: 1.5% 0%;
    }
}

@media screen and (max-width: 767px) {
    .form-field-left,
    .form-field-right {
        margin: 0 auto;
        margin-top: 2%;
    }
    .form-field-left {
        width: 100%;
    }
    .form-field-right {
        width: 100%;
        height: 25vh;
    }
}

/* This is for landscape mode */
@media (max-height: 389px) and (max-width: 843px) {
    .form-field-right {
        height: 16vw;
    }
}
