.projects-section {
    background-color: #0D1B2A;
    color: #E0E1DD;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.project-card {
    position: relative;
    width: 100%;
    height: 30vh;
    margin-bottom: 5vh;
    border-radius: 2vw;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.project-card-image-container {
    position: relative;
    height: 50%;
}

.project-card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-card-info {
    position: relative;
    height: 50%;
    padding: 2vw;
}

.project-card-title {
    font-size: 4vw;
    margin-bottom: 2vh;
}

.project-card-description {
    font-size: 3vw;
}

@media only screen and (min-width: 768px) {
    .project-card {
        height: 50vh;
    } 
}